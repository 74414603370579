import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import { screenSizes } from "model"
import { mobile } from "../../../../../constants/screenSizes"
import LPProductCarouselFullWidthBlock from "./LPProductCarouselFullWidthBlock"
import { Icon } from "semantic-ui-react"
import GoLink from "../../../../button/GoLink"

const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-bottom: 20px;
  max-width: 2000px;
  margin: auto;
`
const Body = styled.div`
  padding: 60px 40px 20px;
  display: flex;
  align-items: flex-end;
  @media (max-width: ${screenSizes.tablet}px) {
    padding: 40px 50px;
  }
  @media (max-width: ${mobile}px) {
    padding: 40px 25px;
  }
`
const Title = styled.h1`
  font-family: Minion serif;
  font-size: 2.4em;
  margin-right: 20px;
  margin-bottom: 0;

  @media (max-width: ${screenSizes.tablet}px) {
    font-size: 2em;
  }
`

const StyledGoLink = styled(GoLink)`
  margin-bottom: 9px;
`

export const LinkIcon = styled(Icon)<{ color: string }>`
  margin-left: 10px;
  color: ${({ color }) => color};
`

interface Props {
  title: string
  collectionName: string
  description?: string
  backgroundColor?: string
  showImpactArrow?: boolean
  useImpactImage?: boolean
  large?: boolean
  to?: string
}

const LPProductCarouselFeatureBlock: FC<Props> = ({
  title,
  collectionName,
  description,
  backgroundColor = "#fcf7eb",
  showImpactArrow,
  useImpactImage,
  large,
  to,
}) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Body>
        <Title>{title}</Title>
        {to && <StyledGoLink to={to}>SEE MORE</StyledGoLink>}
      </Body>

      <LPProductCarouselFullWidthBlock
        collectionName={collectionName}
        hidePrice
        hideBrand
        showImpactArrow={showImpactArrow}
        imageBackground={"white"}
        useImpactImage={useImpactImage}
        large={large}
      />
    </Container>
  )
}

export default LPProductCarouselFeatureBlock
