import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import HomePage from "../social-supermarket/pages/home/HomePage"
import { filterByVisibility } from "../social-supermarket/util/productUtil"
import { ProductType } from "model"

const Container = styled.div``

interface Props {
  pageContext: { products: ProductType[] }
}

const HomePageTemplate: FC<Props> = ({ pageContext: { products } }) => {
  return <HomePage recentProducts={filterByVisibility(products, "Marketplace")} />
}

export default HomePageTemplate
